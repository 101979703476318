/* eslint-disable @typescript-eslint/no-non-null-assertion */
// const HOST = 'https://dev-api.max4.io';
const HOST = 'https://api.max4.io';
// const HOST = 'http://localhost:8000';

export function generateApiUrl(path: string): string {
  return `${HOST}${path}`;
}

export async function signup(
  username: string,
  email: string,
  pw: string,
  phone: string,
  recommendor: string,
  walletAddress: string,
  accountType: 'individual' | 'business',
): Promise<{ noConflict?: { badWalletAddress: boolean } } | null> {
  try {
    const url = accountType == 'individual' ? '/accounts' : '/business-accounts';
    const response = await fetch(generateApiUrl(url), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        email,
        pw,
        phone,
        recommendor,
        'wallet-address': walletAddress,
      }),
    });

    console.log(await response.text());

    if (response.status === 500) return {};
    if (response.status === 400) return { noConflict: { badWalletAddress: true } };
    if (response.status !== 201) throw response;

    return { noConflict: { badWalletAddress: false } };
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function signin(
  username: string,
  pw: string,
  accountType: 'individual' | 'business',
): Promise<{ authenticated?: { wasEmailVerified?: { token: string } } } | null> {
  try {
    const url = accountType == 'individual' ? '/account-sessions' : '/business-account-sessions';
    const response = await fetch(generateApiUrl(url), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        pw,
      }),
    });

    if (response.status === 401) return {};
    if (response.status === 412) return { authenticated: {} };
    if (response.status !== 201) throw response;

    const body = await response.json();
    localStorage.setItem('session', body.token);
    localStorage.setItem('accountType', accountType);

    return { authenticated: { wasEmailVerified: { token: body.token } } };
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function signout(): Promise<void> {
  const session = localStorage.getItem('session');
  const accountType = localStorage.getItem('accountType');
  const url = accountType == 'individual' ? '/account-sessions' : '/business-account-sessions';

  if (!session) return;

  localStorage.removeItem('session');

  try {
    await fetch(generateApiUrl(`${url}/${session}`), {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }
}

export async function getAccount(): Promise<{
  username: string;
  email: string;
  phone: string;
  pointA: number;
  pointB: number;
  pointC: number;
  pointD: number;
  recommender?: string;
  recommendees: Array<string>;
} | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl('/account'), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 412) return null;
    if (response.status !== 200) throw response;

    return await response.json();
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function getBusinessAccount(): Promise<{
  username: string;
  email: string;
  phone: string;
  wallet: string;
  membership?: string;
  wasMembershipApproved: boolean;
  wasMembershipApprovedAt?: Date;
  totalLevel7Sales: string;
  pointA: number;
  pointB: number;
  pointC: number;
  pointD: number;
  pendingPointA: number;
  pendingPointB: number;
  pendingPointC: number;
  pendingPointD: number;
} | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl('/business-account'), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 412) return null;
    if (response.status !== 200) throw response;

    return await response.json();
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function getWallets(): Promise<string[] | null> {
  const session = localStorage.getItem('session');
  const accountType = localStorage.getItem('accountType');
  const url = accountType == 'individual' ? '/account' : '/business-account';

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`${url}/wallets`), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 412) return null;
    if (response.status !== 200) throw response;

    return await response.json();
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function verifyEmail(token: string): Promise<{ success: boolean } | null> {
  try {
    const response = await fetch(generateApiUrl(`/accounts/verified-emails`), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token,
      }),
    });

    if (response.status === 400) return { success: false };
    if (response.status !== 201) throw response;

    return { success: true };
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function verifyBusinessEmail(token: string): Promise<{ success: boolean } | null> {
  try {
    const response = await fetch(generateApiUrl(`/business-accounts/verified-emails`), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token,
      }),
    });

    if (response.status === 400) return { success: false };
    if (response.status !== 201) throw response;

    return { success: true };
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function requestPhoneVerification(phone: string, accountType: 'individual' | 'business'): Promise<{ token: string } | null> {
  const url = accountType == 'individual' ? '/accounts' : '/business-accounts';

  try {
    const response = await fetch(generateApiUrl(`${url}/phones-verification-requests`), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone,
      }),
    });

    if (response.status === 400) return null;
    if (response.status !== 201) throw response;

    const body = await response.json();

    return { token: body.token };
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function verifyPhone(
  token: string,
  code: string,
  accountType: 'individual' | 'business',
): Promise<{ success: boolean } | null> {
  const url = accountType == 'individual' ? '/accounts' : '/business-accounts';

  try {
    const response = await fetch(generateApiUrl(`${url}/phones-verification-requests/${token}`), {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code,
      }),
    });

    if (response.status === 400) return { success: false };
    if (response.status === 404) return { success: false };
    if (response.status !== 200) throw response;

    return { success: true };
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function createGame(mode: string, stage: string): Promise<{ token: string } | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');

    if (!session) return null;

    let url: string;

    if (mode == 'continuous') {
      url = accountType == 'individual' ? '/continuous-challenges' : '/business-continuous-challenges';
    } else if (mode == 'fixed') {
      url = accountType == 'individual' ? '/fixed-challenges' : '/business-fixed-challenges';
    } else {
      throw new Error('unexpected mode');
    }

    const response = await fetch(generateApiUrl(`${url}?${new URLSearchParams({ stage: stage })}`), {
      method: 'POST',
      headers: {
        'Api-Token': session,
      },
    });

    console.log(response.status);

    if (response.status === 401) return null;
    if (response.status === 400) return null;
    if (response.status !== 201) throw response;

    const body = await response.json();

    return { token: body.token };
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function sendBetting(mode: string, token: string, betting: string): Promise<{ success: boolean } | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');

    if (!session) return { success: false };

    let url: string;

    if (mode == 'continuous') {
      url = accountType == 'individual' ? '/continuous-challenges' : '/business-continuous-challenges';
    } else if (mode == 'fixed') {
      url = accountType == 'individual' ? '/fixed-challenges' : '/business-fixed-challenges';
    } else {
      throw new Error('unexpected mode');
    }

    const response = await fetch(generateApiUrl(`${url}/${token}/bet`), {
      method: 'PUT',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        bet: betting,
      }),
    });

    console.log(response.status);

    if (response.status === 401) return { success: false };
    if (response.status === 400) return { success: false };
    if (response.status === 412) return { success: false };
    if (response.status !== 200) throw response;

    return { success: true };
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function getActivatedGame(): Promise<{ token: string; stage: string; mode: string } | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');
    const url = accountType == 'individual' ? '/challenges/active' : '/business-challenges/active';

    if (!session) return null;

    const response = await fetch(generateApiUrl(url), {
      method: 'GET',
      headers: {
        'Api-Token': session,
      },
    });

    if (response.status === 401) return null;
    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const body = await response.json();
    console.log(body);

    if (!body.token || !body.stage || !body.type) return null;

    return {
      token: body.token,
      stage: body.stage,
      mode: body.type,
    };
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

interface challengeInfo {
  bet: string | null;
  closeBetAt: Date;
  endRoundAt: Date;
  createdAt: Date;
  phase: string;
  priceAtBet: number | null;
  priceAtClose: number | null;
  round: number;
  time: Date;
  won: number | null;
  endReason: string | null;
}

export async function getGameState(mode: string, token: string): Promise<challengeInfo | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');

    if (!session) return null;

    let url: string;

    if (mode == 'continuous') {
      url = accountType == 'individual' ? '/continuous-challenges' : '/business-continuous-challenges';
    } else if (mode == 'fixed') {
      url = accountType == 'individual' ? '/fixed-challenges' : '/business-fixed-challenges';
    } else {
      throw new Error('unexpected mode');
    }

    const response = await fetch(generateApiUrl(`${url}/${token}`), {
      method: 'GET',
      headers: {
        'Api-Token': session,
      },
    });

    if (response.status === 401) return null;
    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const body = await response.json();
    console.log(body);

    return {
      bet: body.challenge.bet,
      closeBetAt: new Date(body.challenge.closeBetAt),
      endRoundAt: new Date(body.challenge.endRoundAt),
      createdAt: new Date(body.challenge.createdAt),
      phase: body.challenge.phase,
      priceAtBet: body.challenge.priceAtBet,
      priceAtClose: body.challenge.priceAtClose,
      round: body.challenge.round,
      time: new Date(body.time),
      won: mode == 'fixed' ? body.challenge.won : null,
      endReason: body.challenge.endReason,
    };
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function checkUsernameUniqueness(
  username: string,
  accountType: 'individual' | 'business',
): Promise<{ uniqueness: boolean } | null> {
  const url = accountType == 'individual' ? '/accounts' : '/business-accounts';

  try {
    const response = await fetch(generateApiUrl(`${url}/uniqueness/username?username=${username}`), {
      method: 'GET',
    });

    if (response.status === 401) return null;
    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const body = await response.json();
    console.log(body);

    return {
      uniqueness: body.uniqueness,
    };
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function checkEmailUniqueness(email: string, accountType: 'individual' | 'business'): Promise<{ uniqueness: boolean } | null> {
  const url = accountType == 'individual' ? '/accounts' : '/business-accounts';

  try {
    const response = await fetch(generateApiUrl(`${url}/uniqueness/email?email=${email}`), {
      method: 'GET',
    });

    if (response.status === 401) return null;
    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const body = await response.json();
    console.log(body);

    return {
      uniqueness: body.uniqueness,
    };
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function checkWalletUniqueness(
  wallet: string,
  accountType: 'individual' | 'business',
): Promise<{ uniqueness: boolean } | null> {
  const url = accountType == 'individual' ? '/accounts' : '/business-accounts';

  try {
    const response = await fetch(generateApiUrl(`${url}/uniqueness/wallet?wallet=${wallet}`), {
      method: 'GET',
    });

    if (response.status === 401) return null;
    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const body = await response.json();
    console.log(body);

    return {
      uniqueness: body.uniqueness,
    };
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function checkPhoneUniqueness(phone: string, accountType: 'individual' | 'business'): Promise<{ uniqueness: boolean } | null> {
  const url = accountType == 'individual' ? '/accounts' : '/business-accounts';

  try {
    const response = await fetch(generateApiUrl(`${url}/uniqueness/phone?phone=${encodeURIComponent(phone)}`), {
      method: 'GET',
    });

    if (response.status === 401) return null;
    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const body = await response.json();
    console.log(body);

    return {
      uniqueness: body.uniqueness,
    };
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function getRewards(mode: string, stage: string): Promise<Array<number> | null> {
  try {
    let url: string;

    if (mode == 'continuous') {
      url = '/challenges/continuous-rewards';
    } else if (mode == 'fixed') {
      url = '/challenges/fixed-rewards';
    } else {
      throw new Error('unexpected mode');
    }

    const response = await fetch(generateApiUrl(`${url}?${new URLSearchParams({ stage: stage })}`), {
      method: 'GET',
    });

    if (response.status === 401) return null;
    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const body = await response.json();
    console.log(body);

    const arr = [];
    for (let i = 0; i < 20; i++) {
      arr.push(body[`round${i + 1}`]);
    }

    return arr;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function stopGame(mode: string, token: string): Promise<{ success: boolean } | null> {
  try {
    const accountType = localStorage.getItem('accountType');

    let url: string;

    if (mode == 'continuous') {
      url = accountType == 'individual' ? '/continuous-challenges' : '/business-continuous-challenges';
    } else if (mode == 'fixed') {
      url = accountType == 'individual' ? '/fixed-challenges' : '/business-fixed-challenges';
    } else {
      throw new Error('unexpected mode');
    }

    const session = localStorage.getItem('session');

    if (!session) return null;

    const response = await fetch(generateApiUrl(`${url}/${token}`), {
      method: 'DELETE',
      headers: {
        'Api-Token': session,
      },
    });

    console.log(await response.text());

    if (response.status === 401) return { success: false };
    if (response.status === 400) return { success: false };
    if (response.status === 412) return { success: false };
    if (response.status !== 200) throw response;

    return { success: true };
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function listNotices(
  page: number,
): Promise<{ notices: { id: string; category: string; title: string; author: string; writtenAt: Date }[]; maxPage: number } | null> {
  try {
    const response = await fetch(generateApiUrl(`/boards/notices?${new URLSearchParams({ page: String(page) })}`), {
      method: 'GET',
    });

    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();

    for (const notice of result.notices) notice.writtenAt = new Date(notice.writtenAt);

    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function getNotice(id: string): Promise<{
  notice: { id: string; category: string; title: string; content: string; author: string; writtenAt: Date };
  prev?: { id: string; category: string; title: string; author: string; writtenAt: Date };
  next?: { id: string; category: string; title: string; author: string; writtenAt: Date };
} | null> {
  try {
    const response = await fetch(generateApiUrl(`/boards/notices/${id}`), {
      method: 'GET',
    });

    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();

    result.notice.writtenAt = new Date(result.notice.writtenAt);

    if (result.prev) result.prev.writtenAt = new Date(result.prev.writtenAt);
    if (result.next) result.next.writtenAt = new Date(result.next.writtenAt);

    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function listFAQs(
  page: number,
): Promise<{ faqs: { id: string; category: string; title: string; author: string; writtenAt: Date }[]; maxPage: number } | null> {
  try {
    const response = await fetch(generateApiUrl(`/boards/faqs?${new URLSearchParams({ page: String(page) })}`), {
      method: 'GET',
    });

    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();

    for (const faq of result.faqs) faq.writtenAt = new Date(faq.writtenAt);

    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function getFAQ(id: string): Promise<{
  faq: { id: string; category: string; title: string; content: string; author: string; writtenAt: Date };
  prev?: { id: string; category: string; title: string; author: string; writtenAt: Date };
  next?: { id: string; category: string; title: string; author: string; writtenAt: Date };
} | null> {
  try {
    const response = await fetch(generateApiUrl(`/boards/faqs/${id}`), {
      method: 'GET',
    });

    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();

    result.faq.writtenAt = new Date(result.faq.writtenAt);

    if (result.prev) result.prev.writtenAt = new Date(result.prev.writtenAt);
    if (result.next) result.next.writtenAt = new Date(result.next.writtenAt);

    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export interface InquiryInfo {
  id: string;
  private: boolean;
  author: string;
  category: string;
  title: string;
  content: string;
  writtenAt: Date;
  response?: {
    private: boolean;
    author: string;
    title: string;
    content: string;
    writtenAt: Date;
  };
}

export async function listInquires(page: number): Promise<{
  inquiries: InquiryInfo[];
  maxPage: number;
} | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');
    const url = accountType == 'individual' ? '/boards/inquiries' : '/boards/business-inquiries';

    if (!session) return null;

    const response = await fetch(generateApiUrl(`${url}?${new URLSearchParams({ page: String(page) })}`), {
      method: 'GET',
      headers: {
        'Api-Token': session,
      },
    });

    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();

    for (const inquiry of result.inquiries) {
      inquiry.writtenAt = new Date(inquiry.writtenAt);

      if (inquiry.response) inquiry.response.writtenAt = new Date(inquiry.response.writtenAt);
    }

    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function newInquiry(category: string, title: string, content: string, isPrivate: boolean): Promise<true | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');
    const url = accountType == 'individual' ? '/boards/inquiries' : '/boards/business-inquiries';

    if (!session) return null;

    const response = await fetch(generateApiUrl(url), {
      method: 'POST',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        category,
        title,
        content,
        private: isPrivate,
      }),
    });

    if (response.status === 400) return null;
    if (response.status !== 201) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function getInquiry(id: string): Promise<{
  inquiry: InquiryInfo;
  prev?: InquiryInfo;
  next?: InquiryInfo;
} | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');
    const url = accountType == 'individual' ? '/boards/inquiries' : '/boards/business-inquiries';

    if (!session) return null;

    const response = await fetch(generateApiUrl(`${url}/${id}`), {
      method: 'GET',
      headers: {
        'Api-Token': session,
      },
    });

    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();

    result.inquiry.writtenAt = new Date(result.inquiry.writtenAt);

    if (result.prev) result.prev.writtenAt = new Date(result.prev.writtenAt);
    if (result.next) result.next.writtenAt = new Date(result.next.writtenAt);

    console.log(result);

    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function removeInquiry(id: string): Promise<true | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');
    const url = accountType == 'individual' ? '/boards/inquiries' : '/boards/business-inquiries';

    if (!session) return null;

    const response = await fetch(generateApiUrl(`${url}/${id}`), {
      method: 'DELETE',
      headers: {
        'Api-Token': session,
      },
    });

    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function getExchangeTotal(): Promise<number | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');
    const url = accountType == 'individual' ? '/account' : '/business-account';

    if (!session) return null;

    const response = await fetch(generateApiUrl(`${url}/exchange-requests/total`), {
      method: 'GET',
      headers: {
        'Api-Token': session,
      },
    });

    if (response.status !== 200) throw response;

    return Number(await response.text());
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function newExchangeRequest(point: number): Promise<true | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');
    const url = accountType == 'individual' ? '/account' : '/business-account';

    if (!session) return null;

    const response = await fetch(generateApiUrl(`${url}/exchange-requests`), {
      method: 'POST',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        point,
      }),
    });

    if (response.status === 400) return null;
    if (response.status !== 201) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function changePassword(pw: string, newPw: string): Promise<{ success: boolean } | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');
    const url = accountType == 'individual' ? '/account' : '/business-account';

    if (!session) return null;

    const response = await fetch(generateApiUrl(`${url}/password`), {
      method: 'PUT',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'old-pw': pw,
        'new-pw': newPw,
      }),
    });

    if (response.status === 401) return { success: false };
    if (response.status === 400) return { success: false };
    if (response.status === 412) return { success: false };
    if (response.status !== 200) throw response;

    return { success: true };
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function findUsername(email: string, accountType: 'individual' | 'business'): Promise<true | null> {
  const url = accountType == 'individual' ? '/accounts' : '/business-accounts';

  try {
    const response = await fetch(generateApiUrl(`${url}/lost-and-founds/losts/usernames`), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status !== 201) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function findPassword(username: string, email: string, accountType: 'individual' | 'business'): Promise<true | null> {
  const url = accountType == 'individual' ? '/accounts' : '/business-accounts';

  try {
    const response = await fetch(generateApiUrl(`${url}/lost-and-founds/losts/pws`), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        email,
      }),
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status !== 201) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function getGameHistory(
  mode: string,
  stage: string,
): Promise<Array<{
  time: number;
  win: number;
  lose: number;
} | null> | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');

    if (!session) return null;

    let url: string;

    if (mode == 'continuous') {
      url = accountType == 'individual' ? '/continuous-challenges' : '/business-continuous-challenges';
    } else if (mode == 'fixed') {
      url = accountType == 'individual' ? '/fixed-challenges' : '/business-fixed-challenges';
    } else {
      throw new Error('unexpected mode');
    }

    const response = await fetch(generateApiUrl(`${url}?${new URLSearchParams({ stage: stage })}`), {
      method: 'GET',
      headers: {
        'Api-Token': session,
      },
    });

    if (response.status === 401) return null;
    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const body: Array<{
      bet: string | null;
      closeBetAt: string;
      createdAt: string;
      endRoundAt: string;
      phase: string;
      priceAtBet: number | null;
      priceAtClose: number | null;
      round: number;
      stage: string;
      token: string;
      won: number | null;
      endReason: string;
    }> = await response.json();

    console.log(body);

    const result: Array<{ time: number; win: number; lose: number } | null> = body.map((e) => {
      return {
        time: new Date(e.endRoundAt).getTime() - new Date(e.createdAt).getTime(),
        win: mode == 'fixed' ? e.won! : e.round - 1,
        lose: mode == 'fixed' ? e.round - 1 - e.won! : e.endReason == 'ended' ? 0 : 1,
      };
    });
    for (let i = result.length; i < 5; i++) {
      result.push(null);
    }
    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }
  return null;
}

export async function getCentralWallet(): Promise<string | null> {
  try {
    const response = await fetch(generateApiUrl('/settings/central-wallet-address'), {
      method: 'GET',
    });

    if (response.status === 401) return null;
    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const result = await response.text();
    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }
  return null;
}

export async function editWallet(newWallet: string): Promise<{ success: boolean } | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');
    const url = accountType == 'individual' ? '/account' : '/business-account';

    if (!session) return null;

    const response = await fetch(generateApiUrl(`${url}/wallet-change-requests`), {
      method: 'POST',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        address: newWallet,
      }),
    });

    if (response.status === 401) return { success: false };
    if (response.status === 400) return { success: false };
    if (response.status === 412) return { success: false };
    if (response.status !== 201) throw response;

    return { success: true };
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function confirmWalletUpdated(token: string, accountType: 'individual' | 'business'): Promise<true | null> {
  try {
    const url = accountType == 'individual' ? '/accounts' : '/business-accounts';

    const response = await fetch(generateApiUrl(`${url}/updated-wallet`), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token,
      }),
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status !== 201) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function getExchangeFee(): Promise<number | null> {
  try {
    const response = await fetch(generateApiUrl('/settings/exchange-fee'), {
      method: 'GET',
    });

    if (response.status === 401) return null;
    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const result = Number(await response.text());
    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }
  return null;
}

export interface ExchangeInfo {
  id: string;
  status: string;
  point: number;
  price: number;
  fee: number;
  requestedAt: Date;
}

export async function listExchanges(): Promise<ExchangeInfo[] | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');
    const url = accountType == 'individual' ? '/account' : '/business-account';

    if (!session) return null;

    const response = await fetch(generateApiUrl(`${url}/exchange-requests`), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 401) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();

    for (const exchange of result) {
      exchange.requestedAt = new Date(exchange.requestedAt);
    }
    console.log(result);
    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function resetPassword(token: string, pw: string, accountType: 'individual' | 'business'): Promise<true | null> {
  const url = accountType == 'individual' ? '/accounts' : '/business-accounts';

  try {
    const response = await fetch(generateApiUrl(`${url}/lost-and-founds/founds/pws`), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token,
        pw,
      }),
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status !== 201) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function requestMembershipVerification(): Promise<true | null> {
  try {
    const session = localStorage.getItem('session');

    if (!session) return null;

    console.log('멤버십 인증 요청');

    const response = await fetch(generateApiUrl('/business-account/membership-verification-requests'), {
      method: 'POST',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });
    console.log(response.status);

    if (response.status === 401) return null;
    if (response.status !== 201) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function verifyMembership(token: string): Promise<{ success: boolean } | null> {
  try {
    const response = await fetch(generateApiUrl(`/business-accounts/verified-membership`), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token,
      }),
    });

    if (response.status === 400) return { success: false };
    if (response.status !== 201) throw response;

    return { success: true };
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export interface StructedUserTree {
  canReceiveLevel7Incentives: boolean;
  level1PointC?: string;
  level1PointD?: string;
  level2PointC?: string;
  level2PointD?: string;
  level7PointC?: string;
  membership?: string;
  pointA: number;
  pointB: number;
  pointC: number;
  pointD: number;
  totalLevel7Sales: string;
  username: string;
  wasMembershipApproved: boolean;
  wasMembershipApprovedAt?: Date;
  children: StructedUserTree[];
  sales?: {
    level: number;
    sales: string;
    count: number;
  }[];
}

export async function getBusinessTree(): Promise<StructedUserTree[] | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl('/business-account/tree'), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 412) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();
    const result2 = [];
    result2.push(result);
    return result2;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function getBusinessTotalReward(): Promise<{
  pointA: number;
  pointB: number;
  pointC: number;
  pointD: number;
} | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl('/business-account/reward'), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 412) return null;
    if (response.status !== 200) throw response;

    return await response.json();
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export interface IncentiveInfo {
  id: number;
  reason: string;
  point: string;
  amount: number;
  createdAt: string;
}

export async function getBusinessRewards(): Promise<IncentiveInfo[] | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl('/business-account/rewards'), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 412) return null;
    if (response.status !== 200) throw response;

    return await response.json();
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function newExchangeLivePointRequest(point: number): Promise<true | null> {
  try {
    const session = localStorage.getItem('session');

    if (!session) return null;

    const response = await fetch(generateApiUrl(`/business-account/point-exchange-requests`), {
      method: 'POST',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        point,
      }),
    });

    if (response.status === 400) return null;
    if (response.status !== 201) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export interface FutureTradingSettings {
  multiplier: number;
  point: number;
}

export async function getFutureTradingSettings(): Promise<FutureTradingSettings | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');

    let url;
    if (accountType == 'business') url = '/business-user-future-trading';
    else url = '/future-trading';

    if (!session) return null;

    const response = await fetch(generateApiUrl(`${url}/last-bet-settings`), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const json = await response.json();
    console.log(json);

    return json;
  } catch (err) {
    console.error(err);
    alert('Error occurred');
  }

  return null;
}

export interface FutureTradingBetInfo {
  btcBestAskPriceAtBet: number;
  btcBestBidPriceAtBet: number;
  createdAt: Date;
  id: number;
  long: boolean;
  multiplier: number;
  point: number;
  priceAtBet: number;
  rawPriceAtBet: number;
  spreadAtBet: number;
}

export async function getFutureTradingBets(): Promise<FutureTradingBetInfo[] | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');

    let url;
    if (accountType == 'business') url = '/business-user-future-trading';
    else url = '/future-trading';

    if (!session) return null;

    const response = await fetch(generateApiUrl(`${url}/bets`), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const json: any[] = await response.json();
    json.forEach((e) => (e.createdAt = new Date(e.createdAt)));
    return json;
  } catch (err) {
    console.log(err);
    alert('Error occured');
  }

  return null;
}

export async function betFutureTrading(bet: string, point: number, multiplier: number): Promise<FutureTradingBetInfo | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');

    let url;
    if (accountType == 'business') url = '/business-user-future-trading';
    else url = '/future-trading';

    if (!session) return null;

    const response = await fetch(generateApiUrl(`${url}/bets`), {
      method: 'POST',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        long: bet === 'long',
        point,
        multiplier,
      }),
    });

    if (response.status === 400) return null;
    if (response.status !== 201) throw response;

    const json = await response.json();
    json.createdAt = new Date(json.createdAt);
    console.log(json);
    return json;
  } catch (err) {
    console.log(err);
    alert('Error occured');
  }

  return null;
}

export interface FutureTradingResultInfo {
  btcBestAskPriceAtBet: number;
  btcBestBidPriceAtBet: number;
  createdAt: Date;
  id: number;
  long: boolean;
  multiplier: number;
  point: number;
  priceAtBet: number;
  priceAtClose: number;
  rawPriceAtBet: number;
  resultPoint: number;
  spreadAtBet: number;
}

export async function getFutureTradingResults(): Promise<FutureTradingResultInfo[] | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');

    let url;
    if (accountType == 'business') url = '/business-user-future-trading';
    else url = '/future-trading';

    if (!session) return null;

    const response = await fetch(generateApiUrl(`${url}/results`), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const json: any[] = await response.json();
    json.forEach((e) => (e.createdAt = new Date(e.createdAt)));
    console.log(json);
    return json;
  } catch (err) {
    console.log(err);
    alert('Error occured');
  }

  return null;
}

export async function completeFutureTradingBet(id: number): Promise<FutureTradingResultInfo | null> {
  try {
    const session = localStorage.getItem('session');
    const accountType = localStorage.getItem('accountType');

    let url;
    if (accountType == 'business') url = '/business-user-future-trading';
    else url = '/future-trading';

    if (!session) return null;

    const response = await fetch(generateApiUrl(`${url}/bets/${id}`), {
      method: 'DELETE',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 400) return null;
    if (response.status !== 200) throw response;

    const json = await response.json();
    json.createdAt = new Date(json.createdAt);
    console.log(json);
    return json;
  } catch (err) {
    console.log(err);
    alert('Error occured');
  }

  return null;
}
